<template>
  <div>
    <el-dialog :visible.sync="show">
      <span slot="title">新增/修改 字典</span>
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label><b>分类名称</b></label>
            <el-input type="text" v-model="model.TYPE" />
          </div>
          <div class="form-group">
            <label><b>名称</b></label>
            <el-input type="text" v-model="model.NAME" />
          </div>
          <div class="form-group">
            <label><b>值</b></label>
            <el-input type="text" v-model="model.VALUE"/>
          </div>
          <div class="form-group">
            <label><b>描述</b></label>
            <el-input type="text" v-model="model.DESCRIPTION" />
          </div>
          <div class="form-group">
            <label><b>排序号</b></label>
            <el-input type="text" v-model="model.SORT"/>
          </div>
          <div class="form-group">
            <label><b>父节点ID</b></label>
            <el-input type="number" v-model="model.PARENT_ID"/>
          </div>
        </div>
      </div>
      <div slot="footer">
        <el-button icon="el-icon-close" @click="show=false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "dict_edit",
  data(){
    return{
      show:false,
      model: {}
    }
  },
  methods:{
    init(model){
      this.show=true;
      if (model==undefined || model==null) this.model={};
      else {
        this.model=JSON.parse((JSON.stringify(model)));
      }
    },
    submit(){
      let self=this;
      this.whale.remote.getResult({
        url:"/api/School/FX/DictApi/Save",
        data:self.model,
        completed:function (){
          self.show=false;
          self.$emit("on-saved");
        }
      })
    }
  }
}
</script>
